import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.css'
import 'element-ui/lib/theme-chalk/index.css'

import axios from 'axios'
import {
  Pagination,
  Tooltip
} from 'element-ui';

Vue.use(Tooltip)
Vue.use(Pagination)


Vue.config.productionTip = false

function null2str(data) {
  for (const x in data) {
    if (data[x] === null || data[x] === undefined) { // 如果是null 把直接内容转为 ''
      data[x] = ''
    } else {
      if (Array.isArray(data[x])) { // 是数组遍历数组 递归继续处理
        data[x] = data[x].map(z => {
          return null2str(z)
        })
      }
      if (typeof (data[x]) === 'object') { // 是json 递归继续处理
        data[x] = null2str(data[x])
      }
    }
  }
  return data
}
axios.defaults.baseURL = 'https://p.kolbox.com/kol-api/'
// axios.defaults.baseURL = 'https://p.zingdeal.cn/api/'
// axios.interceptors.request.use(function (config) {
//   const token = localStorage.getItem('HMtiktok_Token')
//   if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
//     config.headers.common['X-Access-Token'] = token
//   }
//   return config
// }, function (error) {
//   // 对请求错误做些什么
//   return Promise.reject(error)
// })
 
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  null2str(response.data.result)
  return response
}, function (error) {

  // 对响应错误做点什么
  return Promise.reject(error)
})
Vue.prototype.$axios = axios
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
