<template>
  <div class="footer">
    <div class="middle">
      <div class="bottom_left">
        <ul>
          <li>
            PRODUCTS
          </li>
          <li v-for="item in list" :key="item.id" @click="toPage(item.id)">
            {{item.name}}
          </li>
        </ul>
        <ul>
          <li>
            CONTACT US
          </li>
          <li>
            Facebook Page: Hookah - Shisha Youbaokuan
          </li>
          <li>
            Mail: chloegztt001@gmail.com
          </li>
          <li>
            Tel：+1 (530)616-8658

          </li>
          <li>
            Mobile: +86 17861127029
          </li>
        </ul>
      </div>
      <div class="bottom_right">
        <img src="../assets/logob.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Foot',
    props: {},
    computed: {},
    created() {
      this.getList()
    },

    data() {
      return {
        list: []
      }
    },
    methods: {
      async getList() {
        const {
          data
        } = await this.$axios.get('cmsproduct/listAll', {
          params: {
            pageSize: 8
          }
        })
        if (data.success) {
          this.list = data.result.records
        }
      },
      toPage(id) {
        this.$router.push(`/ProductInfo?id=${id}`)
      }
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .footer {
    overflow: hidden;
    padding-top: 85px;
    background: #2d4988;
    padding-bottom: 65px;

    .middle {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .bottom_left {
        width: 800px;
        text-align: left;
        display: flex;
        justify-content: space-between;

        ul {
          li {
            color: #FFF;
            cursor: pointer;
            margin-bottom: 5px;
          }

          li:nth-child(1) {
            font-size: 16px;
            margin-bottom: 10px;
          }
        }

        ul:nth-child(2) {
          cursor: auto;

          li {
            cursor: auto;
          }
        }
      }

      .bottom_right {
        overflow: hidden;
        border-left: 1px solid #bcd1ff;
        padding-left: 40px;

        img {
          width: 180px;
        }
      }
    }
  }

</style>
