<template>
  <div class="header">
    <img src="../assets/logo.png" alt="">
    <div class="nav">
      <router-link :class="{'current-route' : currentRoute == 'Home'}" class="nav-item" to="/">Home</router-link>
      <router-link :class="{'current-route' : currentRoute == 'Products'}" class="nav-item" to="/Products">
        Products</router-link>
      <router-link :class="{'current-route' : currentRoute == 'Advantage'}" class="nav-item" to="/Advantage">Our
        Advantage
      </router-link>
      <router-link :class="{'current-route' : currentRoute == 'About'}" class="nav-item" to="/About">About Us
      </router-link>
      <router-link :class="{'current-route' : currentRoute == 'Contact'}" class="nav-item" to="/Contact">Contact Us
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Heade',
    props: {},
    computed: {
      currentRoute() {
        return this.$route.name
      }
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .header {
    display: flex;
    justify-content: center;

    img {
      width: 120px;
      margin-right: 50px;
    }

    .nav {
      display: flex;
      align-items: center;

      .nav-item {
        margin: 0 10px;
        text-decoration: none;
        color: #000;
        font-weight: 700;
        padding: 5px 10px;
        border-radius: 20px;
      }

      .current-route {
        background: #FFBA00;
        color: #FFF;
      }
    }
  }

</style>
