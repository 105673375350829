<template>
  <div class="home">
    <div class="banner">
      <img src="../assets/banner.jpg" alt="">
      <div class="tel">
        <span>let's start the conversation!</span>
        <span>Tel : +8617861127029 / chloegztt001@gmail.com</span>
      </div>
    </div>
    <div class="products">
      <h1>PRODUCT DISPLAY</h1>
      <ul class="product-list">
        <li class="product-item" v-for="item in list" :key="item.id" @click="toPage(item.id)">
          <img :src="item.imgs.split(',')[0]" alt="">
          <p>{{item.name}}</p>
        </li>
      </ul>
      <router-link class="more" to="/Products">
        MORE</router-link>
    </div>
    <div class="about">
      <h1>ABOUT US</h1>
      <div class="about-top">
        <img src="../assets/about.jpg" alt="">
        <div>
          <p>
            Shenzhen YouBaoKuan International Co. Ltd is a manufacturer specializing in the R&D and production of
            hookahs
            and related accessories.
          </p>
          <p> Our factory has complete equipment such as machining centers, large drilling machines, milling machines,
            drilling and milling machines, lathes, CNC lathes, folding machines, punching machines, thread rolling
            machines, and injection molding machines. Our factory has a history of more than 19 years and specializes in
            processing various precision castings and railway pipeline accessories, electromechanical and marine
            accessories, and engineering machinery accessories.
          </p>
          <p> We’re specialized in exporting glass water pipes to North America (U.S. & Canada) and NL. Quality is our
            lifeline, in past years, we have only used High Borosilicate glass and are good at OEM / ODM designs. Our
            company specialized in manufacturing Bongs, Bubblers, Smoking Rigs, Water Bong Pipe Rig, Chillums Pipe,
            Glass
            Blunts, One Hitters Smoking Pipe, Sherlocks Smoking Pipe, Specialty Smoking Pipes, Spoons Smoking Pipe,
            Steamrollers Smoking Pipe, color glass tubes and so on
          </p>
          <p>Advanced production equipment and several production lines to ensure product quality and production.
            Professional team of engineers to create high-precision instrument products. Professional packaging division
            and long-term cooperation in the freight forwarding company, so that our products are safe and lossless,
            convenient transportation, and affordable.
          </p>
          <p> We will stand in the customer’s point of view to consider all the problems, to provide customers with
            quality
            products, and the best service, welcome to sending your inquiry.
          </p>
        </div>
      </div>
      <ul class="about-bottom">
        <li>
          <img src="../assets/about1.jpg" alt="">
        </li>
        <li>
          <img src="../assets/about2.jpg" alt="">
        </li>
        <li>
          <img src="../assets/about3.jpg" alt="">
        </li>
      </ul>
    </div>
    <div class="choose">
      <h1>WHY CHOOSE US?</h1>
      <div class="choose-content">
        <ul style="margin-top:36px">
          <li>
            <img src="../assets/choose1.jpg" alt="">
            <span>01</span>
            <p>Focusing on the dab rigs industry for more than 10 years, we have achieved an annual turnover of 6
              billion dollars in 2020 and have established long-term cooperative relations with customers from more than
              300 shops or regions around the world. One-stop procurement, complete variety.</p>
          </li>
          <li>
            <img src="../assets/choose3.jpg" alt="">
            <span>03</span>
            <p>Our company was identified as Shenzhen Enterprise Technology Center, the company owns 12 national
              patents, passed ISO-9001 quality certification.</p>
          </li>
          <li>
            <img src="../assets/choose5.jpg" alt="">
            <span>05</span>
            <p>Our goal is to make each and every shop seller extremely satisfied. We will not stop innovating and
              improving our products and service. As always your customer feedback, comments, and suggestions are always
              welcome and appreciated.</p>
          </li>
        </ul>
        <ul>
          <li>
            <img src="../assets/choose2.jpg" alt="">
            <span>02</span>
            <p>Youbaokuan is one of the first dab rigs Suppliers in China established in 2010. We have been serving the
              hookah community by providing a wide variety of hookahs, shisha, and hookah accessories to all hookah
              shops alike.</p>
          </li>
          <li>
            <img src="../assets/choose4.jpg" alt="">
            <span>04</span>
            <p>The Hookah Expo Worldwide, a yearly hookah expo that happens in Las Vegas where brands from around the
              world join under one roof. All organized by the number one hookah supplier in the the industry.</p>
          </li>
          <li>
            <img src="../assets/choose6.jpg" alt="">
            <span>06</span>
            <p>There are three special docks, and 15 large logistics companies to realize 72-hours delivery and make
              sure the goods arrive quickly.</p>
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: 'HomeView',
    created() {
      this.getList()
    },
    mounted() {
      document.body.scrollTop = 0
    },
    data() {
      return {
        list: []
      }
    },
    methods: {
      async getList() {
        const {
          data
        } = await this.$axios.get('cmsproduct/listAll', {
          params: {
            pageSize: 8
          }
        })
        if (data.success) {
          this.list = data.result.records
        }
        console.log();
      },
      toPage(id) {
        this.$router.push(`/ProductInfo?id=${id}`)
      }
    }
  }

</script>

<style lang="less" scoped>
  .home {
    .banner {
      img {
        width: 100%;
      }

      .tel {
        width: 100%;
        height: 100px;
        background: url(../assets/bg-01.jpg);
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-weight: 700;
        font-size: 20px;

        span:nth-child(1) {
          font-size: 30px;
        }
      }
    }

    .products {
      height: 1000px;
      width: 100%;
      box-sizing: border-box;
      background: url(../assets/hone-bg.jpg) center;
      padding: 75px;

      h1 {
        color: #fff;
        font-size: 30px;
        background: url(../assets/bg-03.png) no-repeat center;
      }

      .product-list {
        display: flex;
        flex-wrap: wrap;
        width: 1200px;
        margin: 50px auto;

        .product-item {
          cursor: pointer;
          margin: 0 40px 40px 0;
          background: #FFF;

          img {
            width: 260px;
            height: 260px;
          }

          p {
            padding: 15px 0;
            color: #000;
            transition: .3s all;

          }
        }

        .product-item:hover {
          p {
            transition: .3s all;
            background: #FFBA00;
            color: #FFF;
          }
        }
      }

      .more {
        cursor: pointer;
        display: block;
        width: 170px;
        height: 40px;
        border: 1px solid #ffffff;
        margin: 0 auto;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
        font-size: 16px;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;
        line-height: 40px;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
      }

      .more:hover {
        border-radius: 10px;
        transition: all 0.5s;

      }
    }

    .about {
      padding: 75px;

      h1 {
        color: #2d4988;
        font-size: 30px;
        background: url(../assets/bg-05.png) no-repeat center;

      }

      .about-top {
        display: flex;
        justify-content: center;
        width: 1200px;
        margin: 50px auto;

        div {
          margin-left: 20px;
          width: 600px;
          text-align: left;
        }
      }

      .about-bottom {
        display: flex;
        width: 1200px;
        margin: 0 auto;

        li {
          background: url(../assets/bg-04.png) center no-repeat;
          height: 228px;
          overflow: hidden;
          background-size: 100% 228px;
          width: 400px;
          text-align: left;

          img {
            width: 389px;
            height: 220px;
          }
        }
      }

    }

    .choose {
      height: 1987px;
      width: 100%;
      box-sizing: border-box;
      background: url(../assets/banner2.jpg) center;
      padding: 75px;

      h1 {
        color: #fff;
        font-size: 30px;
        background: url(../assets/bg-03.png) no-repeat center;
      }

      .choose-content {
        width: 1200px;
        overflow: hidden;
        background: url(../assets/bg-07.png) top center no-repeat;
        display: flex;
        justify-content: space-between;
        margin: 50px auto;

        ul {
          li {
            position: relative;
            width: 556px;
            overflow: hidden;
            border: 1px solid #fff;
            background: #fff;
            margin-bottom: 55px;

            img {
              width: 556px;
              height: 342px;
              overflow: hidden;
            }

            span {
              top: 300px;
              left: 20px;
              position: absolute;
              width: 72px;
              height: 72px;
              background: #2d4988;
              text-align: center;
              line-height: 72px;
              font-size: 40px;
              color: #ffffff;
            }

            p {
              margin-top: 30px;
              padding: 20px;
              overflow: hidden;
              height: 120px;
              text-align: left;
            }
          }
        }
      }
    }


  }

</style>
