<template>
  <div id="app">
    <Heade></Heade>
    <router-view />
    <Foot></Foot>
  </div>
</template>

<script>
  // @ is an alias to /src

  import Heade from '@/components/Heade.vue'
  import Foot from '@/components/Foot.vue'
  export default {
    name: 'ProductsView',
    components: {
      Heade,
      Foot
    },
    methods: {},
  }

</script>
<style lang="less">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

  }

  .banner {
    img {
      width: 100%;
    }
  }

  .inside_menu {
    overflow: hidden;
    height: 60px;
    background: #2d4988;

    .middle {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      h2 {
        font-size: 20px;
        font-weight: 400;
        color: #ffba00;
        text-transform: uppercase;
        background: url(./assets/bg-11.png) left center no-repeat;
        padding-left: 30px;
        line-height: 60px;
        float: left;
      }

      h3 {
        float: right;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        margin-top: 20px;

        img {
          width: 23px;
        }

        a,
        span {
          cursor: pointer;
          margin: 0 5px;
          color: #ffffff;

        }
      }
    }
  }

  nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

</style>
